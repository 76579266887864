import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';


export default function Context() {
    const HEIGHT = 500;
    return (
        <Box sx={{
            width: '100%', margin: 0,
            padding: 0,
            border: 0,
        }}>
            <Container
                disableGutters={true}
                maxWidth={false}
                sx={{
                    background: `url('./fern_chayac.jpg')`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    width: '100%',
                    margin: 0,
                    padding: 0,
                    border: 0,
                    height: HEIGHT,
                    backgroundRepeat: 'repeat',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    //     position: 'relative',
                    //     backgroundColor: 'grey.800',
                    //     color: '#fff',
                    //     mb: 4,
                    //     backgroundSize: 'cover',
                    //     backgroundRepeat: 'no-repeat',
                    //     backgroundPosition: 'center',
                    //     backgroundImage: `url(${post.image})`,
                    //     height: HEIGHT,
                }}
            >
                <Typography component="h1" color="white" align="center" sx={{ fontSize: 100, textShadow: '2px 2px 8px #000000', }}>
                    Chaya Chopra Carey
                </Typography>
                <Typography component="h2" color="white" align="center" sx={{ fontSize: 40, textShadow: '2px 2px 8px #000000', }}>
                    A software engineer telling big stories with big data
                </Typography>
                <ButtonGroup variant="contained" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', marginTop: '15px', }}>
                    <IconButton sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', }} href="https://linkedin.com/in/chayacarey" target="_blank" >
                        <LinkedInIcon htmlColor="white" />
                    </IconButton>
                    <IconButton sx={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', }} href="https://github.com/chayac" target="_blank" >
                        <GitHubIcon htmlColor="white" />
                    </IconButton>
                </ButtonGroup>

            </Container>
            <Container maxWidth='sm' sx={{ marginTop: '30px', marginBottom: '30px' }}>
                <Typography align="center" component="body" >
                    This site is a work in progress. So am I, tbh.
                </Typography>
            </Container>
        </Box>
    );
}
